import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CategoriesContextHolder from '../context/categories-context';

const CategoriesContextProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const value = useMemo(() => ({ categories, setCategories }), [categories, setCategories]);
  return (
    <CategoriesContextHolder.Provider value={value}>
      {children}
    </CategoriesContextHolder.Provider>
  );
};
CategoriesContextProvider.propTypes = { children: PropTypes.node };
export default CategoriesContextProvider;
