import { useContext, useEffect, useState } from 'react';
import CategoryApi from '../api/CategoryApi';
import CategoriesContext from '../context/categories-context';
import StoreContext from '../context/store-context';
import { objectIsEmpty } from '../utils/utils';
import { constants } from '../utils/constants';

const useCategories = () => {
  const [loading, setLoading] = useState(false);
  const [categoryError, setCategoryError] = useState();
  const { categories, setCategories } = useContext(CategoriesContext);
  const { data } = useContext(StoreContext);
  const { CATEGORY_ALL_ID, CATEGORY_ALL_NAME } = constants;

  useEffect(() => {
    if (objectIsEmpty(data)) return;

    const { storeId } = data;
    setLoading(true);
    CategoryApi.getCategories({ storeId })
      .then((results) => {
        setCategories([
          {
            categoryId: CATEGORY_ALL_ID,
            categoryName: CATEGORY_ALL_NAME,
          },
          ...results.map((category) => category),
        ]);
      })
      .catch((err) => setCategoryError(err))
      .finally(() => setLoading(false));
  }, [data]);

  return { loading, categoryError, categories };
};

export default useCategories;
