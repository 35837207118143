import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Load from '../../components/UI/Load/Load';
import Seo from '../../components/SEO/Seo';
import Error from '../../components/UI/Error/Error';
import SocialMenu from '../../components/UI/SocialMenu/SocialMenu';
import StoreContext from '../../context/store-context';
import ProductApi from '../../api/ProductApi';
import DefaultImage from '../../res/icons/logo.svg';
import './Product.css';
import { objectIsEmpty, formatterCurrecy, markToHTML } from '../../utils/utils';
import { events } from '../../utils/constants';
import { eventClick, viewPage } from '../../utils/analytics';

const Product = () => {
  const [t] = useTranslation('global');
  const { data } = useContext(StoreContext);
  const [productId] = useState(useParams().productId);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productImages, setProductImages] = useState('');
  const [storeSocial, setStoreSocial] = useState({});
  const [viewMore, setViewMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const saveProduct = (product) => {
    const {
      productName: name,
      productPrice: price,
      productDescription: description,
      productImage: images,
    } = product;
    const priceFormatted = formatterCurrecy(price);
    setProductName(name);
    setProductPrice(`${priceFormatted} COP`);
    setProductDescription(description.split('\n').join('<br/>'));
    setProductImages(images);
  };

  useEffect(() => {
    viewPage(window.location.pathname);
    if (!objectIsEmpty(data)) {
      const {
        storeFacebook, storeInstagram, storeEmail,
        storePhone, storeWhatsapp: isWhatsapp, storeId,
        error,
      } = data;

      if (error) {
        setLoading(false);
        return setError(true);
      }

      setStoreSocial({
        mail: storeEmail,
        whatsapp: isWhatsapp ? storePhone : '',
        facebook: storeFacebook,
        instagram: storeInstagram,
        phone: storePhone,
      });
      Promise.all([ProductApi.getProduct({ storeId, productId })])
        .then(([{ isDeleted, ...all }]) => {
          if (isDeleted) setError(true);
          else saveProduct(all);
        })
        .finally(() => setLoading(false));
    }
    return '';
  }, [data]);

  return (
    <div className="p-bar product-container">
      {
        error
          ? (
            <Error
              message={t('not-found')}
              retry={{ link: '/', message: t('back') }}
              fullScreen
            />)
          : (
            <>
              <Seo title={productName} description={productDescription} image={productImages[0]} />
              <div className="product__image">
                {
                  loading
                    ? <Load fullScreen />
                    : (
                      <div className="imageProduct_container">
                        {
                          (productImages.length !== 0)
                            ? productImages.map((image) => (
                              <div className="imageProduct_container-item">
                                <img src={image || DefaultImage} alt="product" />
                              </div>))
                            : (
                              <div className="imageProduct_container-item">
                                <img src={DefaultImage} alt="product" />
                              </div>)
                        }
                      </div>)
                }
                <div className="product__image-overlay">
                  <div className="product__image-overlay-content">
                    <h2 className={loading ? 'shimmer' : ''}>{productName}</h2>
                    <h3 className={loading ? 'shimmer' : ''}>{productPrice}</h3>
                  </div>
                </div>
              </div>
              <div className="product__description">
                {
                  !objectIsEmpty(storeSocial)
                    && <SocialMenu socialMedia={storeSocial} body={t('buy-message') + window.location.href} />
                }
                <div
                  role="button"
                  tabIndex={0}
                  className="product__description-description"
                  onKeyDown={() => setViewMore(!viewMore)}
                  onClick={() => {
                    eventClick({ ...events.PRODUCT_DETAIL });
                    setViewMore(!viewMore);
                  }}
                >
                  <h2>{productName}</h2>
                  {
                    viewMore
                      ? <pre dangerouslySetInnerHTML={{ __html: markToHTML(productDescription) }} />
                      : productDescription.length >= 100
                        ? <pre
                            className={loading ? 'shimmer' : ''}
                            dangerouslySetInnerHTML={{ __html: `${markToHTML(productDescription.substring(0, 100))}...` }}
                        />
                        : <pre
                            className={loading ? 'shimmer' : ''}
                            dangerouslySetInnerHTML={{ __html: markToHTML(productDescription) }}
                        />
                  }
                </div>
                <div className="footer_divider" />
              </div>
            </>)
      }
    </div>
  );
};

export default Product;
