const {
  REACT_APP_BACKEND = 'http://localhost:8080/api',
  REACT_APP_ENVIRONMENT = 'local',
} = process.env;

export default {
  API_BACKEND: REACT_APP_BACKEND,
  ENVIRONMENT: REACT_APP_ENVIRONMENT,
  IS_LOCALHOST: REACT_APP_ENVIRONMENT === 'local',
};
