import { mdToHTML } from './snarkdown';

export const getURLParams = () => {
  const url = new URL(window.location.href);
  return url.pathname.split('/').filter((item) => item !== '');
};

export const objectIsEmpty = (obj) => (Object.entries(obj).length === 0);

export const formatterCurrecy = (value) => {
  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const generateSessionId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const fechaActual = new Date().toISOString().replace(/[-T:.Z]/g, '');
  const cadenaAleatoria = characters.charAt(Math.floor(Math.random() * characters.length));

  sessionStorage.setItem('sessionId', fechaActual + cadenaAleatoria);
  return fechaActual + cadenaAleatoria;
};

export const markToHTML = (data) => mdToHTML(data);
