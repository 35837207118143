import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import debounce from 'just-debounce-it';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  List, ListItem, ChipList, ChipListItem,
} from 'leita-components-ui';
import useNearScreen from '../../hooks/useNearScreen';
import useCategories from '../../hooks/useCategories';
import useProducts from '../../hooks/useProducts';
import Error from '../../components/UI/Error/Error';
import { formatterCurrecy } from '../../utils/utils';
import { eventClick } from '../../utils/analytics';
import { events } from '../../utils/constants';

const Category = () => {
  const [t] = useTranslation('global');
  const [isActive, setIsActive] = useState([]);
  // const { storeName } = useParams();
  const storeName = 'tienda';
  const externalRef = useRef();
  const {
    products,
    error,
    loading,
    setPage,
    setCategory,
  } = useProducts({});
  const { categories, loading: loadingCat } = useCategories({});
  const { isNearScreen } = useNearScreen({
    externalRef: loading ? null : externalRef,
    once: false,
  });

  const debounceHandleNextPage = useCallback(
    debounce(() => setPage((prev) => prev + 1), 200),
    [],
  );

  useEffect(() => {
    if (isNearScreen) debounceHandleNextPage();
  }, [debounceHandleNextPage, isNearScreen]);

  const updateProducts = (category) => {
    setCategory(category);
    setPage(0);
  };

  const changeStatus = (index) => {
    const newArray = isActive.map(() => false);
    newArray[index] = !newArray[index];
    setIsActive(newArray);
  };

  return (
    <>
      <div className="storePage_sticky">
        <ChipList loading={loadingCat}>
          {
            !loadingCat
            && (
              categories.map((category, index) => !category.isDeleted && (
                <ChipListItem
                  picture={category.image || null}
                  label={category.categoryName}
                  value={category.categoryId}
                  key={category.categoryId}
                  active={isActive[index]}
                  onClick={() => {
                    changeStatus(index);
                    updateProducts({ ...category });
                    eventClick({ ...events.CHIP, detail: category.categoryId });
                  }}
                />
              )))
          }
        </ChipList>
        {/* <Button label="Filtrar" style={{ margin: 'inherit' }} />  */}
      </div>
      <div style={{ width: '90%', margin: 'auto' }}>
        { error && <Error message={t('unexpected-error')} fullScreen /> }
        <List loading={loading}>
          {
            (!loading && !error && products.length > 0)
              ? (products.map((product) => !product.isDeleted && (
                <Link to={`/${storeName}/${product.productId}`} key={product.productId}>
                  <ListItem
                    label={product.productName}
                    value={product.productId}
                    description={formatterCurrecy(product?.productPrice)}
                    picture={product.productImage[0]}
                    trailingIcon="chevron-right"
                    onClick={() => {
                      eventClick({ ...events.PRODUCT_ITEM, detail: product.productId });
                    }}
                  />
                </Link>)))
              : <p>No se encontraron resultados</p>
          }
        </List>
        <div id="visor" ref={externalRef} />
      </div>
    </>);
};

export default Category;
