import React, {
  useCallback, useState,
  useEffect,
} from 'react';
import Particles from 'react-particles';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'leita-components-ui';
import { loadSlim } from 'tsparticles-slim';
import './PromoPage.css';
import StoreApi from '../../api/StoreApi';
import { formatterCurrecy, generateSessionId } from '../../utils/utils';
import { socialMediaInfo } from '../../utils/constants';

const PromoPage = () => {
  const [open, setOpen] = useState(false);
  const [productName, setProductName] = useState('');
  const [offerValue, setOfferValue] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productPriceWithOffer, setProductPriceWhitOffer] = useState('');
  const [productImage, setProductImage] = useState('');
  const [coupounId, setCouponId] = useState('');
  const [clientId, setClientId] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isPreload, setIsPreload] = useState(false);
  const particlesInit = useCallback(async (engine) => loadSlim(engine), []);

  const getOffer = (clientIdUseEffect) => {
    if (isPreload) return setOpen(true);

    Promise.all([StoreApi.getOfferProduct(clientIdUseEffect || clientId)])
      .then(([{
        productName, offerValue,
        productPrice, productImage, couponId,
      }]) => {
        const price = productPrice - ((offerValue * productPrice) / 100);
        setProductName(productName);
        setProductPrice(productPrice);
        setProductPriceWhitOffer(price);
        setProductImage(productImage[0]);
        setOfferValue(offerValue);
        setCouponId(couponId);
        setIsActive(true);
        setIsPreload(true);
      });
    setOpen(true);

    return null;
  };

  useEffect(() => {
    const storage = window.localStorage.getItem('usti');
    const { clientId, ...all } = JSON.parse(storage || '{}');

    let newClientId = clientId;
    if (!clientId) {
      newClientId = generateSessionId();
      window.localStorage.setItem('usti', JSON.stringify({ clientId: newClientId, ...all }));
    }

    setClientId(newClientId);
    StoreApi.clientExists(newClientId)
      .then(({
        productName, offerRandomValue,
        productPrice, productImage, couponId,
        isActive,
      }) => {
        const price = productPrice - ((offerRandomValue * productPrice) / 100);
        setProductName(productName);
        setProductPrice(productPrice);
        setProductPriceWhitOffer(price);
        setProductImage(productImage[0]);
        setOfferValue(offerRandomValue);
        setCouponId(couponId);
        setIsActive(isActive);
        setIsPreload(true);
        setOpen(true);
      })
      .catch(() => setTimeout(() => getOffer(newClientId), 3000));
  }, []);

  return (
    <div className="promo-image-container">
      <img
        alt="Imagen de bienvenida"
        src={require('../../res/images/bienvenida.png')}
      />
      <Button
        label="¡Descuento Exclusivo Aquí!"
        className="btn-promo"
        onClick={() => getOffer()}
      />
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={false}
        options={{
          fpsLimit: 120,
          particles: {
            color: {
              value: ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42', '#ffa62d', '#ff36ff'],
            },
            opacity: {
              value: { min: 0, max: 1 },
              animation: {
                enable: true,
                speed: 0.5,
                startValue: 'max',
                destroy: 'min',
              },
            },
            life: {
              duration: {
                sync: true,
                value: 5,
              },
              count: 1,
            },
            move: {
              angle: {
                value: 45,
                offset: 0,
              },
              drift: 0,
              enable: true,
              gravity: {
                enable: true,
                acceleration: 9.81,
              },
              speed: 45,
              decay: 0.1,
              direction: -90,
              random: true,
              straight: false,
              outModes: {
                default: 'none',
                bottom: 'destroy',
              },
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 300,
            },
            shape: {
              type: ['square', 'circle'],
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <Modal show={open} onHide={() => setOpen(false)} centered>
        <Modal.Header>
          <Modal.Title>{isActive ? '¡Felicidades!' : 'Ya usaste tu cupón'}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <h1>Ganaste {offerValue}% de descuento en:</h1>
          <h4>{productName}</h4>
          <div style={{ width: '100%', height: '300px', borderRadius: '10px' }}>
            <img
              style={{
                width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px',
              }}
              alt="Imagen producto"
              src={productImage}
            />
          </div>
          <p style={{ opacity: '0.5', textDecorationLine: 'line-through', color: 'red' }}>
            Precio actual: {formatterCurrecy(productPrice)}
          </p>
          <h1>Precio: {formatterCurrecy(productPriceWithOffer)}</h1>
          <h2>Cupon: {coupounId}</h2>
        </Modal.Body>
        <Modal.Footer>
          <a href="https://wa.me/c/573203907599">Ver más productos</a>
          <Button
            type="primary"
            disabled={!isActive}
          >
            {
              isActive && (
                <a href={`
                  ${socialMediaInfo.whatsapp}3203907599&text=¡Hola, gane este cupón ${coupounId}`}
                >
                  ¡Lo quiero!
                </a>
              )
            }
            {!isActive && '¡Lo quiero!'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>);
};

export default PromoPage;
