import React, { useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import storeInformationContext from '../context/store-context';

const reducer = (data, action) => {
  if (action) {
    return { ...data, ...action };
  }
  return { ...data };
};

const StoreInformationDataHolder = ({ children }) => {
  const [data, dispatchData] = useReducer(reducer, { });
  const value = useMemo(() => ({ data, dispatchData }), [data]);
  return (
    <storeInformationContext.Provider value={value}>
      {children}
    </storeInformationContext.Provider>
  );
};
StoreInformationDataHolder.propTypes = { children: PropTypes.node };
export default StoreInformationDataHolder;
