import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
// eslint-disable-next-line
import firebaseConfig from './firebase-config.json';

firebase.initializeApp(firebaseConfig);

export const { analytics } = firebase;
export default firebase;
