import React, { useEffect } from 'react';
import Seo from '../../components/SEO/Seo';
import Hero from '../../components/Hero/Hero';
// import Pricing from '../../components/Pricing/Pricing';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/UI/Footer/Footer';
// import Service from '../../components/Service/Service';
import './LandingPage.css';
import { viewPage } from '../../utils/analytics';

const LandingPage = () => {
  useEffect(() => {
    viewPage(window.location.pathname);
    sessionStorage.clear();
  }, []);

  return (
    <>
      <Seo title="Inicio" />
      <div className="body-wrap p-bar">
        <main>
          <section className="hero">
            <Hero />
          </section>

          { /* <section className="features-container">
            <div className="container">
              <div className="features-inner section-inner has-bottom-divider">
                <div className="features-wrap">
                  <Service
                    title="Be Productive"
                    description="Fermentum posuere urna nec tincidunt praesent semper
                    feugiat nibh. A arcu cursus vitae congue mauris. Nam at lectus
                    urna duis convallis. Mauris rhoncus aenean vel elit scelerisque mauris."
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="pricing">
            <div className="container-sm">
              <div className="section-inner">
                <div className="pricing-header text-center">
                  <h2 className="mt-0">Unlimited for all</h2>
                  <p className="section-paragraph mb-0">Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua. Ut ad quis nostrud.</p>
                </div>
                <Pricing />
              </div>
            </div>
          </section> */ }

          <Banner message="Próximamente más información... " btnLink="/tienda" btnText="¡Vamos a comprar!" />
        </main>
        <Footer />
      </div>
    </>);
};

export default LandingPage;
