import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonItem } from 'leita-components-ui';
import { useTranslation } from 'react-i18next';
import { socialMediaInfo, events } from '../../../utils/constants';
import { eventClick } from '../../../utils/analytics';

const SocialMenu = ({ socialMedia = {}, body = '' }) => {
  const [t] = useTranslation('global');
  const [socialList, setSocialList] = useState([]);

  useEffect(() => {
    const list = Object.entries(socialMedia).map((social) => (social));
    const filterList = list.filter(([, value]) => value);
    setSocialList(filterList.map((item) => item));
  }, [socialMedia]);

  return (
    <ToggleButton label={t('buy')} icon="cart">
      {
        (socialList || []).map(([key, value]) => (
          <a
            key={key}
            target="_blank"
            rel="noopener noreferrer"
            href={`${socialMediaInfo[key]}${value}${key === 'whatsapp' ? `&text=${body}` : ''}`}
          >
            <ToggleButtonItem
              value={key}
              icon={key}
              onClick={() => eventClick({ ...events.SOCIAL, detail: key })}
            />
          </a>
        ))
      }
    </ToggleButton>);
};

SocialMenu.protoType = {
  socialMedia: PropTypes.Object,
  body: PropTypes.String,
};

export default SocialMenu;
