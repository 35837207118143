import React from 'react';
import PropTypes from 'prop-types';
import './Banner.css';

const Banner = ({ message, btnText, btnLink }) => (
  <section className="cta">
    <div className="container-banner">
      <div className="cta-inner section-inner">
        <h3 className="section-title mt-0">{ message }</h3>
        <div>
          <a className="btn btn-primary" href={btnLink}>{ btnText }</a>
        </div>
      </div>
    </div>
  </section>);

Banner.propTypes = {
  message: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
};

export default Banner;
