import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SideBar, SideBarItem } from 'leita-components-ui';
import { generateSessionId } from '../../../utils/utils';
import StoreApi from '../../../api/StoreApi';
import StoreContext from '../../../context/store-context';
import logo from '../../../res/images/LOUMIT.png';
import './Header.css';

const Header = () => {
  const [t] = useTranslation('global');
  const [, setStoreName] = useState('');
  const [slugUrl, setSlugUrl] = useState('');
  const { dispatchData } = useContext(StoreContext);

  useEffect(() => {
    generateSessionId();
    // const [storeName] = getURLParams();
    const storeName = 'tienda';
    if (storeName) {
      Promise.all([
        StoreApi.getStoreInformationAsync({ storeName }),
      ])
        .then(([storeInfo]) => {
          const { slugUrl, storeName } = storeInfo;
          dispatchData({ ...storeInfo });
          setSlugUrl(slugUrl);
          setStoreName(storeName);
        })
        .catch(() => (dispatchData({ error: true, message: t('unexpected-error') })));
    }
  }, []);

  return (
    <div className="header_container">
      <SideBar title={
        <div className="image-header">
          <img src={logo} alt="loumitos-petshop" />
        </div>
      }
      >
        <Link to={`/${slugUrl ? `${slugUrl}` : ''}`}>
          <SideBarItem label="Inicio" icon="home" />
        </Link>
      </SideBar>
    </div>);
};

export default Header;
