import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Seo from '../../SEO/Seo';
import './Load.css';
import { loadEvent } from '../../../utils/analytics';
import { events } from '../../../utils/constants';

const Load = ({ fullScreen }) => {
  const [t] = useTranslation('global');

  useEffect(() => {
    if (fullScreen) loadEvent({ ...events.LOAD });
  }, []);

  return (
    <>
      { fullScreen && <Seo title={t('loading-title')} /> }
      <section className={`container__load ${fullScreen ? 'fullScreen' : ''}`}>
        <div className="spinner" />
      </section>
    </>);
};

Load.protoType = {
  fullScreen: PropTypes.Boolean,
};

export default Load;
