import { api, escalateError, getResponseData } from './index';

export default class ProductrApi {
  static getProducts(body) {
    return api.post('/product/products', body)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getProductsByCategory(body) {
    return api.post('/product/category', body)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getProduct({ storeId, productId }) {
    return api.get(`/product/${storeId}/${productId}`)
      .then(getResponseData)
      .catch(escalateError);
  }
}
