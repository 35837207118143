import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import DefaultImg from '../../res/icons/logo.svg';

const Seo = ({ title = '', description = '', image = '' }) => {
  const [t] = useTranslation('global');
  return (
    <Helmet>
      <title>{`${title} - ${t('page-name')}`}</title>
      <meta
        name="description"
        content={description.split('\n').join('') || t('page-description')}
      />
      <link rel="apple-touch-icon" href={image || DefaultImg} />
    </Helmet>);
};

Seo.protoType = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default Seo;
