import React from 'react';
import { ReactComponent as Facebook } from '../../../res/icons/facebook.svg';
import { ReactComponent as Github } from '../../../res/icons/github.svg';
import { ReactComponent as Instagram } from '../../../res/icons/instagram.svg';
import './Footer.css';

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <div className="site-footer-inner">
        <ul className="footer-social-links list-reset">
          <li>
            <a href="https://www.facebook.com/hugo.a.talledos/">
              <span className="screen-reader-text">Facebook</span>
              <Facebook />
            </a>
          </li>
          <li>
            <a href="https://github.com/HugoTalledos">
              <span className="screen-reader-text">Github</span>
              <Github />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/hugo_conh/">
              <span className="screen-reader-text">Instagram</span>
              <Instagram />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>);

export default Footer;
