import axios from 'axios';
import config from '../utils/config';

const { API_BACKEND } = config;

export const api = axios.create({
  baseURL: API_BACKEND || 'http://localhost:8080/api',
  headers: { 'Content-Type': 'application/json' },
});

export const apiPublicIp = axios.create({
  baseURL: 'https://api.ipify.org?format=json',
});

/* export const apiEvent = axios.create({
  baseURL: API_BACKEND || 'http://localhost:8080/api',
  headers: { 'Content-Type': 'application/json' },
}); */

export const getApiURL = () => API_BACKEND;
export const getResponseData = (resp) => resp.data.data;

export const escalateError = (err) => {
  let errorFromResponse;
  try {
    errorFromResponse = err.response.data.error.toString();
  } catch (e) {
    errorFromResponse = undefined;
  }
  const newErr = new Error(errorFromResponse
    || (err instanceof Error
      ? err.message || err.toString()
      : typeof err === 'string'
        ? err
        : err.toString() || 'Error Inesperado'));
  try {
    newErr.data = err.response.data;
  } catch (e) {
    // console.error(e);
  }
  throw newErr;
};

export default api;
