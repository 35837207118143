import React from 'react';
import lou from '../../res/images/lou.png';
import './Hero.css';

const Hero = () => (
  <div className="hero-inner">
    <div className="hero-copy">
      <h1 className="mt-0">¡Bienvenidos a Loumitos!</h1>
      <p>Mi nombre es Lou (Aunque se pronuncia Lu) y esta es mi tienda virtual,
        en donde encontraras lo mejor para tu lomito.
      </p>
      <div className="hero-cta">
        <a className="btn btn-primary" href="/tienda">Ir a tienda</a>
      </div>
    </div>
    <div className="hero-figure">
      <div className="hero-image-container">
        <img src={lou} alt="imagen-lou" />
      </div>
      <div className="hero-figure-box hero-figure-box-01" data-rotation="45deg" />
      <div className="hero-figure-box hero-figure-box-02" data-rotation="-45deg" />
      <div className="hero-figure-box hero-figure-box-03" data-rotation="0deg" />
      <div className="hero-figure-box hero-figure-box-04" data-rotation="-135deg" />
      <div className="hero-figure-box hero-figure-box-06" />
      <div className="hero-figure-box hero-figure-box-07" />
      <div className="hero-figure-box hero-figure-box-08" data-rotation="-22deg" />
    </div>
  </div>);

export default Hero;
