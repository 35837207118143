import React, { useState } from 'react';
import { Theme } from 'leita-components-ui';
import '@mdi/font/css/materialdesignicons.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';
import Header from './components/UI/Header/Header';
import LandingPage from './pages/LandingPage/LandingPage';
import StorePage from './pages/StorePage/StorePage';
import Product from './pages/Product/Product';
import Error from './components/UI/Error/Error';
import StoreContext from './data/store-info-data';
import ProductContextProvider from './data/products-data';
import CategoriesContextProvider from './data/categories-data';
import { fontFamily } from './utils/constants';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Redirect from './pages/Redirect';
import PromoPage from './pages/PromoPage/PromoPage';

const App = () => {
  const history = createBrowserHistory();
  const [t] = useTranslation('global');
  const [isOnline, setIsOnline] = useState(true);
  const updateOnlineStatus = () => {
    if (navigator.onLine) window.location.reload();
    else setIsOnline(false);
  };

  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  return (
    <Theme
      primary="#FF932F"
      backgroundColor="#fbfbfb"
      darkPrimary="#591F0A"
      accent="#eeeeee"
      black="#202020"
      white="#FFFBFC"
      shadow="#DADADA"
      fontFamily={fontFamily}
    >
      <Router history={history}>
        <StoreContext>
          <Header />
          <CategoriesContextProvider>
            <ProductContextProvider>
              {
                isOnline
                  ? (
                    <Routes>
                      <Route exact path="/catalogo/Hugo" element={<Redirect />} />
                      <Route exact path="/" element={<LandingPage />} />
                      <Route exact path="/promo" element={<PromoPage />} />
                      <Route exact path="/tienda" element={<StorePage />} />
                      <Route exact path="/tienda/:productId" element={<Product />} />
                      <Route
                        exact
                        path="*"
                        element={
                          <Error
                            fullScreen
                            message={t('not-found')}
                            retry={{ link: '/', message: t('back') }}
                          />
                        }
                      />
                    </Routes>)
                  : (<Error fullScreen message={t('offline')} icon="offline" />)
              }
            </ProductContextProvider>
          </CategoriesContextProvider>
        </StoreContext>
      </Router>
    </Theme>);
};

export default App;
