export const notificationTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  DEFAULT: 'default',
};

export const errorTypes = {
  LOGIN_ERROR: 'login-error',
  USER_NOT_FOUND: 'user-not-register',
  UNEXPECTED_ERROR: 'unexpected-error',
};

export const successTypes = {
  LOGIN_SUCCESS: 'login-success',
};

export const socialMediaInfo = {
  whatsapp: 'https://api.whatsapp.com/send?phone=57',
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  mail: 'mailto:',
  phone: 'tel:',
};

export const constants = {
  CATEGORY_ALL_ID: '0',
  CATEGORY_ALL_NAME: 'Todo',
};

export const fontFamily = [
  'Raleway',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
];

export const events = {
  FIRST_EVENT: { label: 'new', code: 'F01' },
  CHIP: { label: 'chip-category', code: 'C10' },
  SOCIAL: { label: 'social-button', code: 'S20' },
  PRODUCT_DETAIL: { label: 'product-detail', code: 'P30' },
  PRODUCT_ITEM: { label: 'product-item', code: 'P31' },
  LOAD: { label: 'load-screen', code: 'L98' },
  ERROR: { label: 'error-screen', code: 'E99' },
};
