import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { objectIsEmpty } from '../../utils/utils';
import Seo from '../../components/SEO/Seo';
import Category from '../Category/Category';
import Error from '../../components/UI/Error/Error';
import Load from '../../components/UI/Load/Load';
import SocialMenu from '../../components/UI/SocialMenu/SocialMenu';
import StoreContext from '../../context/store-context';
import DefaulImage from '../../res/icons/logo.svg';
import './StorePage.css';
import { viewPage } from '../../utils/analytics';
// import StoreApi from '../../api/StoreApi';

const StorePage = () => {
  const [t] = useTranslation('global');
  const { data } = useContext(StoreContext);
  const [, setStoreName] = useState('tienda');
  const [storeDescription, setStoreDescription] = useState('');
  const [storeFacebook, setStoreFacebook] = useState('');
  const [storeInstagram, setStoreInstagram] = useState('');
  const [isWhatsapp, setIsWhatsapp] = useState('');
  const [storePhone, setStorePhone] = useState('');
  const [storeEmail, setStoreEmail] = useState('');
  const [storeImage, setStoreImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    viewPage('Tienda');
    if (objectIsEmpty(data)) return '';

    // const urlParams = new URLSearchParams(window.location.search);
    // const tableId = urlParams.get('tid');

    const {
      // storeId,
      storeName: name,
      storeDescription: description,
      storePhone: phone,
      storeWhatsapp,
      storeFacebook: facebook,
      storeInstagram: instagram,
      storeEmail: mail,
      storeLogo,
      error,
    } = data;

    if (error) {
      setMessage(t('not-found'));
      setLoading(false);
      return setError(true);
    }

    /* if (tableId) {
      StoreApi.sendEventQr({ storeId, tableId, origin: 'qr' });
      urlParams.delete('tid');
      window.history.replaceState(null, '', `?${urlParams.toString()}`);
    } */

    setIsWhatsapp(storeWhatsapp);
    setStoreName(name);
    setStoreDescription(description);
    setStorePhone(phone);
    setStoreEmail(mail);
    setStoreFacebook(facebook);
    setStoreInstagram(instagram);
    setStoreImage(storeLogo[0]);
    setLoading(false);
    return '';
  }, [data]);

  return (
    <> {
    loading
      ? <Load fullScreen />
      : !error
        ? (
          <>
            <Seo title="Tienda" description={storeDescription} image={storeImage} />
            <div className="store-page">
              <div className="store-page__header">
                <div>
                  <img src={storeImage || DefaulImage} alt="storeLogo" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: storeDescription.split('\n').join('<br/>') }} />
              </div>
              <SocialMenu
                socialMedia={{
                  mail: storeEmail,
                  whatsapp: isWhatsapp ? storePhone : '',
                  facebook: storeFacebook,
                  instagram: storeInstagram,
                  phone: storePhone,
                }}
                body={t('greeting-message')}
              />
            </div>
            <Category />
            <div className="footer_divider" />
          </>
        )
        : (
          <Error
            message={message || t('not-found')}
            retry={{ link: '/', message: t('back') }}
            fullScreen
          />
        )
    }
    </>);
};

export default StorePage;
