import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Seo from '../../SEO/Seo';
import './Error.css';
import { errorEvent } from '../../../utils/analytics';

const Error = ({
  message, retry, fullScreen, icon = 'not_found',
}) => {
  const [t] = useTranslation('global');

  useEffect(() => {
    if (fullScreen) errorEvent({ message });
  }, []);

  return (
    <>
      { fullScreen && <Seo title={t('error-title')} /> }
      <section className={`containerMain__error ${fullScreen ? 'fullScreen' : ''}`}>
        <div className="containerMain__error--information">
          <img
            src={require(`../../../res/icons/${icon}.svg`)}
            alt="notFound"
          />
          <p>{message || 'Parece que algo salio mal, lo sentimos :('}</p>
        </div>
        {
          retry && (
            <div>
              <a href={retry.link} className="btn full-width btn-primary">{retry.message}</a>
            </div>
          )
        }
      </section>
    </>);
};

Error.protoType = {
  message: PropTypes.String,
  retry: PropTypes.Object,
  fullScreen: PropTypes.Boolean,
  icon: PropTypes.String,
};

export default Error;
