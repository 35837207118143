import { useContext, useEffect, useState } from 'react';
import ProductApi from '../api/ProductApi';
import ProductContext from '../context/product-context';
import StoreContext from '../context/store-context';
import { objectIsEmpty } from '../utils/utils';
import { constants } from '../utils/constants';

const INITIAL_ITEM = 0;

const useProducts = ({
  limit = 5,
  orderKey = 'productOrder',
  order = 'asc',
}) => {
  const { CATEGORY_ALL_ID, CATEGORY_ALL_NAME } = constants;
  const categoryDefualt = { categoryId: CATEGORY_ALL_ID, categoryName: CATEGORY_ALL_NAME };
  const [productError, setProductError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [category, setCategory] = useState(categoryDefualt);
  const [page, setPage] = useState(INITIAL_ITEM);
  const [lastResults, setLastResults] = useState([]);
  const { products, setProducts } = useContext(ProductContext);
  const { data } = useContext(StoreContext);

  const categoryToUse = category || sessionStorage.getItem('category') || CATEGORY_ALL_ID;

  useEffect(() => {
    if (objectIsEmpty(data)) return;
    setLoading(true);

    const { storeId } = data;
    const body = {
      storeId,
      limit,
      orderKey,
      order,
    };
    const { categoryId } = category;
    const promise = categoryId === CATEGORY_ALL_ID
      ? ProductApi.getProducts(body)
      : ProductApi.getProductsByCategory({ ...body, categoryId });

    Promise.all([promise])
      .then(([response]) => {
        setProducts(response);
        setLoading(false);
        setLastResults(response);
        sessionStorage.setItem('category', JSON.stringify(category));
      })
      .catch((error) => setProductError(error));
  }, [category, categoryToUse, setProducts, data]);

  useEffect(() => {
    if (page === INITIAL_ITEM) return;
    if (objectIsEmpty(data)) return;
    if (lastResults.length === 0) return;
    setLoadingNextPage(true);
    const { storeId } = data;
    const lastItem = products[products.length - 1];
    const body = {
      storeId,
      limit,
      orderKey,
      lastItem: lastItem ? lastItem[orderKey] : null,
      order,
    };
    const { categoryId } = category;

    const promise = categoryId === CATEGORY_ALL_ID
      ? ProductApi.getProducts(body)
      : ProductApi.getProductsByCategory({ ...body, categoryId });

    Promise.all([promise])
      .then(([results]) => {
        setLastResults(results);
        if (products.length === 0) setProducts([]);
        setProducts((prevsProds) => prevsProds.concat(results));
      })
      .catch((err) => setProductError(err))
      .finally(() => setLoadingNextPage(false));
  }, [categoryToUse, page, setProducts]);

  return {
    products,
    productError,
    loading,
    loadingNextPage,
    setPage,
    setCategory,
  };
};

export default useProducts;
