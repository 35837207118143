import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ProductContextHolder from '../context/product-context';

const ProductContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const value = useMemo(() => ({ products, setProducts }), [products, setProducts]);
  return (
    <ProductContextHolder.Provider value={value}>
      {children}
    </ProductContextHolder.Provider>
  );
};
ProductContextProvider.propTypes = { children: PropTypes.node };
export default ProductContextProvider;
