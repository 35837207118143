import config from './config';
import { events } from './constants';
import { analytics } from './firebase';

const { IS_LOCALHOST } = config;

const sessionId = sessionStorage.getItem('sessionId') || 'F01';

export const viewPage = (page) => (!IS_LOCALHOST
  && analytics().logEvent('page_view', { page_path: page, sessionId }));

export const eventClick = ({ label, code, detail = '' }) => (!IS_LOCALHOST
  && analytics().logEvent(label, {
    sessionId,
    action: 'click',
    detail,
    value: sessionId === '00' ? events.FIRST_EVENT.code : code,
  }));

export const errorEvent = ({ message }) => (!IS_LOCALHOST
  && analytics().logEvent('error', {
    sessionId,
    detail: message,
    value: events.ERROR.code,
  }));

export const loadEvent = () => (!IS_LOCALHOST
  && analytics().logEvent('load', {
    sessionId,
    value: events.ERROR.code,
  }));
