import {
  api, apiPublicIp, getResponseData, escalateError,
} from './index';

export default class StoreApi {
  static async getStoreInformationAsync({ storeName }) {
    return api.post('/user/getUser', { storeName })
      .then(({ data }) => {
        const { storeId, storeName: slug } = data.data;
        return Promise.all([
          api.get(`/store/${storeId}`),
        ])
          .then(([store]) => ({
            ...store.data.data,
            slugUrl: slug,
          }))
          .catch(escalateError);
      })
      .catch(escalateError);
  }

  static async getOfferProduct(publicIp) {
    return api.get(`/offer/${publicIp}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static async getPublicIp() {
    return apiPublicIp.get('/')
      .then(({ data }) => data)
      .catch(escalateError);
  }

  static async clientExists(publicIp) {
    return api.get(`/offer/client/${publicIp}`)
      .then(getResponseData)
      .catch(escalateError);
  }
  /* static async sendEventQr({ storeId, tableId, origin }) {
    apiEvent.post('/event', { storeId, tableId, origin })
      .then((e) => console.log(e.data))
      .catch((e) => console.log(e.data));
  } */
}
